body{
    font-family: 'Open Sans';
}


::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar:hover {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #c1c2c3; 
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
    borderColor: '#24275d !important'
  }




